import Vue from "vue";

/*TITLE*/
document.title = "Viviendas Casablanca | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Viviendas Casablanca";
Vue.prototype.$subtitle = "¿te quedas a vivir aquí?";

/*INTRO*/
Vue.prototype.$promoter = "Viviendas Casablanca";
Vue.prototype.$introSubtitle = "¿te quedas a vivir aquí?";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon_forest-colonial--20230116100158.jpg";
Vue.prototype.$image_kitchen = "";
Vue.prototype.$image_bath1 = "banyo-ppal_vela-natural_deco-bremen-roble-natural--20230116100113.jpg";
Vue.prototype.$image_bath2 = "banyo-2_bottega-caliza_spiga-bottega-caliza--20230116100124.jpg";
Vue.prototype.$image_room = "dormitorio_forest-colonial--20230116100136.jpg";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon_forest-colonial--20230116100158.jpg",
  },
  {
    src: "salon_forest-cognac--20230116100102.jpg",
  },
  {
    src: "salon_forest-acero--20230116100105.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banyo-ppal_vela-natural_deco-bremen-roble-natural--20230116100113.jpg",
  },
  {
    src: "banyo-ppal_rivoli_deco-bremen-roble-natural--20230116100117.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banyo-2_bottega-caliza_spiga-bottega-caliza--20230116100124.jpg",
  },
  {
    src: "banyo-2_bottega-acero_spiga-bottega-acero--20230116100128.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
  {
    src: "dormitorio_forest-colonial--20230116100136.jpg",
  },
  {
    src: "dormitorio_forest-cognac--20230116100143.jpg",
  },
  {
    src: "salon_forest-acero--20230116100147.jpg",
  },
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=c%20de%20ávila%20&%20c%20senda%20perdida%2028805%20alcalá%20de%20henares%20madrid",
    text: "C. de Ávila & C. Senda Perdida - 28805 Alcalá de Henares, Madrid",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=c%20de%20ávila%2028805%20alcalá%20de%20henares%20madrid",
    text: "C. de Ávila - 28805 Alcalá de Henares, Madrid",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:914560997",
    text: "914560997",
  },
];
